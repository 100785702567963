"use strict";

import "regulus-oscar";
import AOS from 'aos';

AOS.init();

$(function(){
    try {
        var bannerDismissed = window.localStorage.getItem('cookie-banner-dismissed');
    }
    catch (e) {
        // Skip showing the banner entirely if localStorage is disabled because we can't dismiss it.
        return;
    }
    if( !bannerDismissed ){
        $('body').append(
            '<div class="js-cookie-message alert alert-dismissible fade show bg-dark text-light mb-0" role="alert">'
            + '<p>By continuing to use this website, you agree to our use of cookies. <a href="/privacy-statement/">Learn more</a> about how we use cookies.</p>'
            + '<button type="button" id="cookie-dismiss" data-dismiss="alert" aria-label="Close" class="btn btn-sm btn-light">OK, I agree</button></div>'
        );
        $('#cookie-dismiss').click(function(e){
            window.localStorage.setItem('cookie-banner-dismissed', '1');
        });
    }
});
